import React, { useContext, useState, useEffect } from "react";
import { ShopContext } from "../../context/ShopContext";
import ProductCard from "./ProductCard";
import { Row, Col } from "react-bootstrap";
import { getShopText, getJSCMVideos } from "../../contentful";
import TitleBar from "../TitleBar";
import marked from "marked";
import JSMCMenu from "../JSMCMenu";
import { FaShoppingBasket } from "react-icons/fa";

const Shop = () => {
  const { toggleModal, products, fetchProducts, basket, openCart } = useContext(
    ShopContext
  );
  const [text, setText] = useState([]);
  const [video, setVideo] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchProducts();
    getShopText().then((data) => setText(data));
    getJSCMVideos().then((data) => setVideo(data));
  }, []);

  let basketCount;
  if (basket.lineItems) {
    basketCount = <span>{basket.lineItems.length}</span>;
  }
  return (
    <div className='content'>
      <TitleBar title='The JS Modular Cornett' />
      <JSMCMenu />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
      >
        <h4>Welcome to the shop</h4>
        <div onClick={openCart}>
          <FaShoppingBasket size={25} />{" "}
          <span style={{ marginLeft: 5 }}>{basketCount}</span>
        </div>
      </div>

      <Row>
        <Col md={4}>
          {products.slice(0, 1).map((item) => (
            <ProductCard key={item.id} item={item} />
          ))}
        </Col>
        <Col md={8}>
          <div className='text-center'>
            {text.map((text) => {
              const content = marked(text.fields.content);

              return (
                <div style={{ textAlign: "justify", padding: 40 }}>
                  <p>
                    NB 10% student discount available on all orders: please{" "}
                    <span
                      onClick={toggleModal}
                      style={{ cursor: "pointer", textDecoration: "underline" }}
                    >
                      {" "}
                      contact me
                    </span>{" "}
                    for a discount code.
                  </p>

                  <div dangerouslySetInnerHTML={{ __html: content }} />
                </div>
              );
            })}
            {video.slice(0, 1).map((video) => (
              <video
                key={video.fields.video.fields.file.url}
                style={{ width: "75%" }}
                controls
              >
                <source src={video.fields.video.fields.file.url} />
              </video>
            ))}
          </div>
        </Col>
      </Row>
      <hr />
      <h5>Additional parts currently available to order separately</h5>
      <Row>
        {products &&
          products.slice(1, products.length).map((item) => (
            <Col md={4}>
              {" "}
              <ProductCard key={item.id} item={item} />
            </Col>
          ))}
      </Row>
      <h5>Mouthpieces</h5>
      {text.map((text) => {
        let content = marked(text.fields.mouthpieces);
        let comingSoon = marked(text.fields.comingSoon);
        return (
          <div>
            <div dangerouslySetInnerHTML={{ __html: content }} />
            <div dangerouslySetInnerHTML={{ __html: comingSoon }} />
          </div>
        );
      })}
    </div>
  );
};

export default Shop;

import React, { useContext } from "react";
import {ShopContext} from '../context/ShopContext'
import { Row, Col, Modal } from "react-bootstrap";


const ContactBar = () => {
  const {showModal, toggleModal} = useContext(ShopContext)
  return (
    <div className="contact-bar">
      <div className="m-2 text-center">
          <button onClick={toggleModal} className="special-btn">
            Contact Me
          </button>
      </div>

      <Modal show={showModal}>
        <div style={{ color: "black", padding: 35 }}>
          <h3>Contact Me</h3>

          <form
            action="https://formspree.io/f/mpzobbod"
            method="POST"
            className="form-group"
          >
            Name: <input name="name" className="form-control" type="text" />
            Email:{" "}
            <input className="form-control" type="email" name="_replyto" />
            Message: <textarea name="message" className="form-control" />
            <div style={{ marginTop: 20 }}>
              <button type="submit" className="special-btn">
                Send
              </button>
              <button
                type="button"
                onClick={toggleModal}
                style={{ marginLeft: 10 }}
                className="special-btn-outline"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default ContactBar;

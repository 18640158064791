import React, {useState, useEffect} from 'react'
import { getJSMCResearchText } from '../contentful'
import TitleBar from './TitleBar'
import marked from 'marked'
import JSMCMenu from './JSMCMenu'

const JSModularCornettResearch = () => {
    const [content, setContent] = useState([])

    useEffect(() => {
        getJSMCResearchText().then(data => setContent(data))
    },[])

    return (
        <div className="content">
            <TitleBar title="JS Modular Cornett - Research Background" />
            <JSMCMenu/>

            {content.map(data => {
                let text = marked(data.fields.content)
                return <div dangerouslySetInnerHTML={{__html: text}} />


            })}

        </div>
    )
}

export default JSModularCornettResearch

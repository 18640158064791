import React from "react";
import { Link } from "react-router-dom";
import {
  FaFacebook,
  FaShoppingCart,
  FaInstagram,
  FaTwitter,
} from "react-icons/fa";
import { Dropdown, NavLink, NavItem } from "react-bootstrap";
import DrawerToggleButton from "../DrawerToggleButton/DrawerToggleButton";

import "./SideDrawer.css";

const sideDrawer = (props) => {
  let drawerClasses = "side-drawer";
  if (props.show) {
    drawerClasses = "side-drawer open";
  }
  return (
    <div>
      <nav className={drawerClasses}>
        <div style={{ padding: "15px" }} className='toolbar__toggle-button'>
          <DrawerToggleButton click={props.click} />
        </div>
        <ul>
          <div>
            <li>
              <Link onClick={props.click} to='/'>
                <div className='header'>
                  <h2>JS</h2>
                </div>
              </Link>
            </li>
            <li>
              <Link
                className='custom-nav-link'
                onClick={props.click}
                to='/biography'
              >
                Biography
              </Link>
            </li>
            <li>
              <Link
                onClick={props.click}
                className='custom-nav-link'
                to='/projects'
              >
                Current Activities
              </Link>
            </li>
            <li>
              <Dropdown>
                <Dropdown.Toggle as={NavLink}>
                  Projects and Ensembles
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    {" "}
                    <Link onClick={props.click} to='/contemporarycornett'>
                      Contemporary Cornett
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    {" "}
                    <Link onClick={props.click} to='/gonzagaband'>
                      Gonzaga Band
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    {" "}
                    <Link onClick={props.click} to='/hmsc'>
                      HMSC
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    {" "}
                    <Link onClick={props.click} to='/gusto'>
                      Gusto Renaissance Winds
                    </Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li>
              <Dropdown as={NavItem}>
                <Dropdown.Toggle as={NavLink}>
                  JS Modular Cornett
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    {" "}
                    <Link onClick={props.click} to='/js-modular-cornett'>
                      About the JS Modular Cornett
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    {" "}
                    <Link onClick={props.click} to='/shop'>
                      Buy Online
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    {" "}
                    <Link onClick={props.click} to='/jsmc-research-background'>
                      Research Background
                    </Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>

            <li>
              <Link
                className='custom-nav-link'
                onClick={props.click}
                to='/recordings'
              >
                Discography
              </Link>
            </li>
            <li>
              <Link
                className='custom-nav-link'
                onClick={props.click}
                to='/shop'
              >
                Shop
              </Link>
            </li>

            {/* <li>
                <Link className="custom-nav-link" onClick={props.click} to="/contact">
                  Contact</Link>
                </li> */}
            {/* <li>
                    <Link onClick={props.click} to="/store">
                    <FaShoppingCart/>
                  </Link>
                    </li> */}
          </div>
        </ul>
      </nav>
    </div>
  );
};

export default sideDrawer;

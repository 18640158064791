import React, {useState, useEffect} from 'react'
import marked from 'marked'
import { getBiog } from '../contentful'
import { Row, Col } from 'react-bootstrap'
import { FaDownload } from 'react-icons/fa'

const Biog = () => {
    const [content, setContent] = useState([])

    useEffect(() => {
        getBiog().then(data => setContent(data))
    }, [])
    return (
        <div>
            {content.map(biog => {
                let text = marked(biog.fields.text)

                return <div style={{padding:30}}>
                    <Row>
                        <Col md={4}>
                        <img src={biog.fields.image.fields.file.url} style={{width:'100%'}}/>
                        <a href={biog.fields.image.fields.file.url} download className="btn btn-dark mt-3" >download photo <FaDownload/></a>

                        </Col>
                        <Col md={8}>
                        <h3>Jamie Savan</h3>
                        <div style={{textAlign:'justify'}} dangerouslySetInnerHTML={{__html: text}}/>
</Col>
                    </Row>
                </div>
            })}
        </div>
    )
}

export default Biog

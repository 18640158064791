import React, {useState, useEffect} from 'react'
import {getProjects, getContemporaryCornettPage} from '../contentful'
import TitleBar from './TitleBar'
import ProjectCard from './ProjectCard'
import {Row, Col} from 'react-bootstrap'
import marked from 'marked'
import ScrollAnimation from 'react-animate-on-scroll'
import "animate.css/animate.compat.css";


const ContemporaryCornett = () => {
    const [projects, setProjects] = useState([])
    const [content, setContent ] = useState([])
    useEffect(() => {
        getProjects().then(data => setProjects(data.filter(x => x.fields.contemporaryCornett)))
        getContemporaryCornettPage().then(data => setContent(data) )
    },[])
    return (
        <div className="content">
            <TitleBar title="The Contemporary Cornett"/>
    {content.map(content => {
        const mainText = marked(content.fields.mainText)
        const mainText1a = marked(content.fields.textBlock1a)
        const text2 = marked(content.fields.textBlock2)

        return(
            <div>
        <Row>

        <Col md={6}>
        <img src={content.fields.image.fields.file.url} style={{width:'100%', marginBottom:20}} />

        <div style={{textAlign:'justify'}} dangerouslySetInnerHTML={{__html: mainText}} />
        <div style={{marginBottom:10}} >
                    <audio controls><source src={content.fields.audio.fields.file.url}/></audio>
                    </div>
        <div style={{textAlign:'justify'}} dangerouslySetInnerHTML={{__html: mainText1a}} />
        </Col>
    <Col md={6}>  
    <h4>Latest Activity</h4>
    <div className="activity-scroller">
    {projects.map(project => 
    <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
                <ProjectCard project={project} />
                </ScrollAnimation>
            )}
            </div>
            </Col>
</Row>    

<div dangerouslySetInnerHTML={{__html: text2}} />
        
</div> ) } )}

          
        </div>
    )
}

export default ContemporaryCornett

const client = require("contentful").createClient({
  space: "vg02cm5apksf",
  accessToken: "kKxPKsTl10DLgK_l8ol1aq9Qg2AQJahD722re9w0Nw8",
});

const getProject = (slug) =>
  client
    .getEntries({ content_type: "project", "sys.id": slug })
    .then((response) => response.items);
const getProjects = () =>
  client
    .getEntries({
      content_type: "project",
      order: "fields.enterNumber12Or3ToFeatureOnTheHomepage",
    })
    .then((response) => response.items);
const getBiog = () =>
  client
    .getEntries({ content_type: "biographyPage" })
    .then((response) => response.items);
const getRecordings = () =>
  client
    .getEntries({ content_type: "recording" })
    .then((response) => response.items);
const getRecordingsList = () =>
  client
    .getEntries({ content_type: "discographyList" })
    .then((response) => response.items);
const getJSCMVideos = () =>
  client
    .getEntries({ content_type: "jsmcVideo" })
    .then((response) => response.items);
const getJSMCText = () =>
  client
    .getEntries({ content_type: "jsmcText" })
    .then((response) => response.items);
const getShopText = () =>
  client
    .getEntries({ content_type: "shopText" })
    .then((response) => response.items);
const getJSMCResearchText = () =>
  client
    .getEntries({ content_type: "jsmcResearchPage" })
    .then((response) => response.items);
const getHomepageText = () =>
  client
    .getEntries({ content_type: "homePage" })
    .then((response) => response.items);
const getContemporaryCornettPage = () =>
  client
    .getEntries({ content_type: "contemporaryCornettoPage" })
    .then((response) => response.items);
const getHMSCPage = () =>
  client
    .getEntries({ content_type: "hmscPage" })
    .then((response) => response.items);
const getGonzagasPage = () =>
  client
    .getEntries({ content_type: "gonzagaBandPage" })
    .then((response) => response.items);
const getGustoPage = () =>
  client
    .getEntries({ content_type: "gustoPage" })
    .then((response) => response.items);

export {
  getContemporaryCornettPage,
  getGonzagasPage,
  getHMSCPage,
  getHomepageText,
  getJSMCResearchText,
  getShopText,
  getJSMCText,
  getJSCMVideos,
  getProjects,
  getProject,
  getBiog,
  getRecordings,
  getRecordingsList,
  getGustoPage,
};

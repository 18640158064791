import React from 'react'

const style={
    minHeight: '50px',
    color: 'white',
    background: 'black',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}
const Footer = (props) => {
return <div style={style}>
    <small>Designed & developed by Adam Woolf @ <a style={{color:'white'}} href='https://www.webspinner.eu'>WebSpinner.eu</a></small>
</div>
}

export default Footer
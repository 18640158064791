import React from 'react'
import { Link } from 'react-router-dom'

const HomeProjectCard = ({project, desc}) => {
    return (
        <div key={project.sys.id}> 
            <div>
                <div className="main-box ">
                <div className="overlay home-project-card-overlay">
          
                    <p><b>{project.fields.title}</b></p>
               
                <section dangerouslySetInnerHTML={{ __html: desc }} />
                <Link className="btn btn-light" to={`/projects/${project.sys.id}`}>
                more...</Link>
                </div>
                <div className="home-project-card">
                <div style={{height:70}} >
                    <h5>{project.fields.title}</h5>
                    </div>
                    <div className="home-project-card-image-wrapper" >
                   {project.fields.mainImage && <img src={project.fields.mainImage.fields.file.url}/>}
                    </div>
                    </div>
                </div>
               
            </div>
            <hr/>
        </div>
    )
}

export default HomeProjectCard

import React, { useState, useEffect } from "react";
import { getProjects, getJSCMVideos, getHomepageText } from "../contentful";
import { Row, Col } from "react-bootstrap";
import marked from "marked";
import jamie from "../assets/jamie.jpg";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import HomeProjectCard from "./HomeProjectCard";

const Home = () => {
  const [projects, setProjects] = useState([]);
  const [video, setVideo] = useState([]);
  const [text, setText] = useState([]);

  useEffect(() => {
    getProjects().then((data) => {
      console.log(data);
      setProjects(data);
    });
    getJSCMVideos().then((data) => {
      const filtered = data.filter((x) => x.fields.homePage);
      setVideo(filtered);
    });
    getHomepageText().then((data) => setText(data));
  }, []);

  return (
    <div className='content'>
      <p className="welcome-text" >
        Welcome to my website which brings together some of my diverse
        activities as a cornettist and performing musician. For information on
        my research activities please visit my academic profile at{" "}
        <a
          href='https://www.bcu.ac.uk/research/our-people/n-s/jamie-savan'
          target='_blank'
        >
          {" "}
          Royal Birmingham Conservatoire
        </a>
        .
      </p>
      <Row>
        <Col md={4}>
          <img src={jamie} style={{ width: "100%" }} />
        </Col>

        <Col>
          <h2>Recent Activities</h2>
          <Row>
            {projects.slice(0, 3).map((project) => {
              let desc;
              if (project.fields.description) {
                desc =
                  marked(project.fields.description)
                    .split(" ")
                    .slice(0, 15)
                    .join(" ") + "...";
              }
              return (
                <Col>
                  <HomeProjectCard project={project} desc={desc} />
                </Col>
              );
            })}
          </Row>

          <Link to='/projects' className='btn btn-sm btn-dark'>
            to Activities Timeline <FaArrowRight />
          </Link>
        </Col>
      </Row>
      <div className='mt-4'>
        <h2>The JS Modular Cornett</h2>
        <Row>
          {video.map((vid) => (
            <Col md={6}>
              <video controls>
                <source src={vid.fields.video.fields.file.url} />
              </video>
            </Col>
          ))}

          <Col md={6}>
            {text.map((text) => {
              const content = marked(text.fields.jsmcText);
              return <div dangerouslySetInnerHTML={{ __html: content }} />;
            })}
          </Col>
        </Row>
        <Link to='/js-modular-cornett' className='btn btn-dark'>
          Find out more
        </Link>
        <Link to='/shop' className='btn btn-light'>
          Buy Here
        </Link>
      </div>
    </div>
  );
};

export default Home;

import React, { useState, useEffect } from "react";
import { getProjects, getGustoPage } from "../contentful";
import TitleBar from "./TitleBar";
import ProjectCard from "./ProjectCard";
import { Row, Col } from "react-bootstrap";
import marked from "marked";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.compat.css";

const Gusto = () => {
  const [projects, setProjects] = useState([]);
  const [content, setContent] = useState([]);

  useEffect(() => {
    getProjects().then((data) =>
      setProjects(data.filter((x) => x.fields.gusto))
    );
    getGustoPage().then((data) => setContent(data));
  }, []);
  return (
    <div className='content'>
      <TitleBar title='Gusto Renaissance Winds' />
      {content.map((text) => {
        let content = marked(text.fields.mainText);
        return (
          <div className='ensemble-page-content'>
            <div
              className='ensemble-page-content-text'
              dangerouslySetInnerHTML={{ __html: content }}
            />
            <div className='ensemble-page-content-image'>
              <img src={text.fields.image.fields.file.url} />
            </div>
          </div>
        );
      })}

      {projects.map((project) => (
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
          <ProjectCard project={project} />
        </ScrollAnimation>
      ))}
    </div>
  );
};

export default Gusto;

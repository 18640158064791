import React, {useState, useEffect} from 'react'
import {getProjects, getHMSCPage  } from '../contentful'
import TitleBar from './TitleBar'
import ProjectCard from './ProjectCard'
import {Row, Col} from 'react-bootstrap'
import marked from 'marked'
import ScrollAnimation from 'react-animate-on-scroll'
import "animate.css/animate.compat.css";


const HMSC = () => {
        const [projects, setProjects] = useState([])
        const [content, setContent] = useState([])

        useEffect(() => {
            getProjects().then(data => setProjects(data.filter(x => x.fields.hmsc)))
            getHMSCPage().then(data => setContent(data))
        },[])
        return (
            <div className="content">
                <TitleBar title="His Majesty's Sagbutts & Cornetts"/>
                {content.map(content => {
                    const text = marked(content.fields.mainText)
                    return <div>
                        <Row>
                            <Col md={6} >
                            <div dangerouslySetInnerHTML={{__html: text}} style={{textAlign:'justify'}} /></Col>
                            <Col md={6} >
                            <img src={content.fields.image.fields.file.url} style={{width:'100%'}} /></Col>
                        </Row>

                    </div>
                })}
                {projects.map(project => 
                <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
                    <ProjectCard project={project} />
                    </ScrollAnimation>
                )}
            </div>
        )

}

export default HMSC

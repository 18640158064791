import React, { useEffect, createContext, useReducer } from "react";
import Client from "shopify-buy";

const ShopContext = createContext(null);

const client = Client.buildClient({
  domain: "jsmodularcornett.myshopify.com",
  storefrontAccessToken: "65a4a68d9de0e3fa24ef1e82fae82dec",
});

const reducer = (state, action) => {
  switch (action.type) {
    case "set-products":
      return { ...state, products: action.payload };

    case "set-product":
      return { ...state, product: action.payload };

    case "set-basket":
      return { ...state, basket: action.payload };

    case "cart-open":
      return { ...state, cartOpen: action.payload };

    case "number-of-items":
      return { ...state, numberOfItems: action.payload };

    case "add-item":
      return {
        ...state,
        basket: action.payload,
        numberOfItems: state.numberOfItems + 1,
      };

    case "remove-item":
      return {
        ...state,
        basket: action.payload,
        numberOfItems: state.numberOfItems - 1,
      };

    case "TOGGLE_MODAL":
      return {
        ...state,
        showModal: !state.showModal,
      };

    case "show-modal":
      return { ...state, showModal: action.payload };

    default:
      return state;
  }
};

const ShopProvider = (props) => {
  const toggleModal = () => {
    dispatch({ type: "TOGGLE_MODAL" });
  };

  const [state, dispatch] = useReducer(reducer, {
    products: [],
    product: {},
    basket: {},
    cartOpen: false,
    numberOfItems: 0,
    // showModal: false
  });

  useEffect(() => {
    createCheckout();
  }, []);

  const createCheckout = async () => {
    const checkout = await client.checkout.create();
    dispatch({ type: "set-basket", payload: checkout });

    console.log(checkout);
  };

  const fetchCheckout = (id) => {
    client.checkout.fetch(id).then((checkout) => {
      dispatch({ type: "set-basket", payload: checkout });
    });
  };

  const fetchProducts = async () => {
    const products = await client.product.fetchAll();
    dispatch({ type: "set-products", payload: products });

    console.log(products);
  };

  const fetchProductWithId = async (id) => {
    console.log("ID", id);
    const product = await client.product.fetch(id);
    dispatch({ type: "set-product", payload: product });

    console.log(product);
  };

  const closeCart = () => dispatch({ type: "cart-open", payload: false });

  const openCart = () => dispatch({ type: "cart-open", payload: true });

  const addItemToCart = async (variantId, quantity) => {
    const lineItemsToAdd = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ];
    console.log(lineItemsToAdd);
    const checkout = await client.checkout.addLineItems(
      state.basket.id,
      lineItemsToAdd
    );
    dispatch({ type: "add-item", payload: checkout });
  };

  const removeLineItemInCart = async (lineItemId) => {
    await client.checkout
      .removeLineItems(state.basket.id, lineItemId)
      .then((res) => {
        dispatch({ type: "remove-item", payload: res });
      });
  };
  const updateQuantity = async (lineItemId, quantity) => {
    const checkoutId = state.basket.id;
    const lineItemsToUpdate = [{ id: lineItemId, quantity: +quantity }];

    return await client.checkout
      .updateLineItems(checkoutId, lineItemsToUpdate)
      .then((res) => {
        dispatch({ type: "set-basket", payload: res });
        //   console.log(lineItemsToUpdate)
      });
  };

  const decrementQuantity = (lineItemId) => {
    const itemId = lineItemId.id;
    const updatedQuantity = lineItemId.quantity - 1;
    updateQuantity(itemId, updatedQuantity);
    dispatch({ type: "number-of-items", payload: state.numberOfItems - 1 });
    // console.log(this.state.numberOfItems)
  };

  const incrementQuantity = (lineItemId) => {
    const itemId = lineItemId.id;
    const updatedQuantity = lineItemId.quantity + 1;
    updateQuantity(itemId, updatedQuantity);
    // setNumberOfItems(numberOfItems + 1)
    dispatch({ type: "number-of-items", payload: state.numberOfItems + 1 });
  };
  return (
    <ShopContext.Provider
      value={{
        products: state.products,
        product: state.product,
        fetchProducts,
        fetchProductWithId,
        cartOpen: state.cartOpen,
        openCart,
        closeCart,
        addItemToCart,
        basket: state.basket,
        removeLineItemInCart,
        incrementQuantity,
        decrementQuantity,
        showModal: state.showModal,
        toggleModal,
      }}
    >
      {props.children}
    </ShopContext.Provider>
  );
};
const MainConsumer = ShopContext.Consumer;
export { MainConsumer, ShopContext };

export default ShopProvider;

import React, { Component } from "react";
import {
  FaTwitter,
  FaShoppingCart,
  FaYoutube,
  FaSpotify,
  FaFacebook,
  FaShoppingBasket,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Toolbar.css";
import DrawerToggleButton from "../DrawerToggleButton/DrawerToggleButton";
import { NavLink } from "react-bootstrap";

// import { ShopContext } from "../../context/ShopContext";

class toolbar extends Component {
  constructor(props) {
    super(props);
  }

//   static contextType = ShopContext;

  render() {
    return (
      <header className="toolbar">
        <nav className="toolbar__navigation">
          <div className="toolbar__toggle-button">
            <DrawerToggleButton click={this.props.drawerClickHandler} />
          </div>

          <div >
            <Link className="link" to="/">
          <h1>Jamie Savan</h1>
          </Link>
          </div>
          <div className="toolbar_navigation-items">
            <Link to="/shop" className="special-btn link d-none d-sm-block">JS Modular Cornett <FaShoppingBasket/></Link>
             
             
             
           
          </div>
        </nav>
      </header>
    );
  }
}
export default toolbar;

import React, {useState, useEffect} from 'react'
import { getProjects } from '../contentful'
import { Row, Col } from 'react-bootstrap'
import marked from 'marked'
import ProjectCard from './ProjectCard'
import ScrollAnimation from 'react-animate-on-scroll'
import "animate.css/animate.compat.css";


const ProjectsTimeline = () => {
    const [projects, setProjects] = useState([])

    useEffect(() => {
        getProjects().then(data => setProjects(data))
    }, [])

    return (
        <div className="content">
            <h2 >Activities Timeline</h2>
        {projects.map(project => {
          

return (
    <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut" >
  <ProjectCard project={project} />
  </ScrollAnimation>
)

        })}
        </div>
    )
}

export default ProjectsTimeline

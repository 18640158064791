import React, { useState, useContext } from "react";
import { ShopContext } from "./context/ShopContext";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import Home from "./components/Home";
import ProjectsTimeline from "./components/ProjectsTimeline";
import HMSC from "./components/HMSC";
import Gonzagas from "./components/Gonzagas";
import ContemporaryCornett from "./components/ContemporaryCornett";
import Shop from "./components/shop/Shop";
import Contact from "./components/Contact";
import Discography from "./components/Discography";
import Backdrop from "./components/Backdrop/Backdrop";
import SideDrawer from "./components/Sidedrawer/SideDrawer";
import Toolbar from "./components/Toolbar/Toolbar";
import Biog from "./components/Biog";
import JSModularCornettResearch from "./components/JSModularCornettResearch";
import JSModularCornett from "./components/JSModularCornett";
import ProjectFull from "./components/ProjectFull";
import Footer from "./components/Footer";
import SingleProductPage from "./components/shop/SingleProductPage";
import Cart from "./components/shop/Cart";
import ContactBar from "./components/ContactBar";
import Gusto from "./components/Gusto";

function App() {
  const { cartOpen, openCart, closeCart } = useContext(ShopContext);

  const [menuOpen, setMenuOpen] = useState(false);

  const backdropClickHandler = () => {
    setMenuOpen(false);
    closeCart();
  };

  const sideDrawerOpen = () => {
    setMenuOpen(true);
  };

  const drawerToggleClickHandler = () => {
    if (menuOpen) setMenuOpen(false);
    if (!menuOpen) {
      setMenuOpen(true);
    }
  };

  let backdrop;
  if (menuOpen || cartOpen) {
    backdrop = <Backdrop click={backdropClickHandler} />;
  }

  return (
    <div>
      <Toolbar
        openCart={openCart}
        drawerClickHandler={drawerToggleClickHandler}
      />
      <SideDrawer show={menuOpen} click={drawerToggleClickHandler} />
      <Cart show={cartOpen} />
      {backdrop}

      <main style={{ marginTop: "66px" }}></main>
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/projects/:id' component={ProjectFull} />
        <Route path='/projects' component={ProjectsTimeline} />
        <Route path='/hmsc' component={HMSC} />
        <Route path='/gonzagaband' component={Gonzagas} />
        <Route path='/gusto' component={Gusto} />
        <Route path='/contemporarycornett' component={ContemporaryCornett} />
        <Route path='/shop/:id' component={SingleProductPage} />
        <Route path='/shop' component={Shop} />
        <Route path='/contact' component={Contact} />
        <Route path='/recordings' component={Discography} />
        <Route path='/biography' component={Biog} />
        <Route path='/js-modular-cornett' component={JSModularCornett} />
        <Route
          path='/jsmc-research-background'
          component={JSModularCornettResearch}
        />
      </Switch>
      <ContactBar />
      <Footer />
    </div>
  );
}

export default App;

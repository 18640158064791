import React, { useState, useEffect } from "react";
import { getRecordings, getRecordingsList } from "../contentful";
import { Row, Col } from "react-bootstrap";
import marked from 'marked'
import TitleBar from "./TitleBar";


const Discography = () => {
  const [cds, setCds] = useState([]);
  const [list, setList] = useState([])

  useEffect(() => {
    getRecordings().then((data) => setCds(data));
    getRecordingsList().then((data) => setList(data));
  }, []);



  return (
    <div className="content">
<TitleBar title="Discography" />
    {cds.filter(cd => cd.fields.featured).map(cd => {

      let desc = marked(cd.fields.description)
      let youtube;
      if(cd.fields.youTubeVideo){youtube = marked(cd.fields.youTubeVideo)}


      return <div className="featured-cd-card" >
        <h4>Featured Recording</h4>
          <Row>
            <Col md={8}>
              <h5>{cd.fields.title}</h5>
              <h6>{cd.fields.artist}</h6>
              <section dangerouslySetInnerHTML={{__html: desc}} />
              
              {cd.fields.youTubeVideo && <>
               <div style={{marginTop:10}} dangerouslySetInnerHTML={{__html: youtube}} /> 
               </>}
              </Col>
            <Col md={4}>
            <img src={cd.fields.cover.fields.file.url} style={{width:'100%'}}/>
            {cd.fields.mp3 && cd.fields.mp3.map(track => <>
                  <small>{track.fields.title}</small><br/>
                <audio controls> 
                <source src={track.fields.file.url} type="" />
                </audio></>)}
            
            </Col>
          </Row>

      </div>
    })
    
    }

{list.map(list => {
      const content = marked(list.fields.list)
return  <>
<h4>Complete Discography</h4>
<div dangerouslySetInnerHTML={{__html: content}}
  />
</>

})}


{/* 
        {cds.map((cd) => (
            <div className="cd-card">
              <p><span><em>{cd.fields.title}</em></span> <span>- {cd.fields.artist}</span>  {cd.fields.label && <span>-  {cd.fields.label}</span>}</p>
            </div>
        ))} */}
    </div>
  );
};

export default Discography;

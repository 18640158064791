import React from "react";
import marked from "marked";
import { Link } from "react-router-dom";

const ProjectCard = ({ project }) => {
  let desc;
  if (project.fields.description) {
    desc = marked(project.fields.description);
  }

  return (
    <div className='project-card'>
      <div className='project-card-left-panel'>
        <div>
          <h4>{project.fields.title}</h4>
          <div className='project-card__main-content'>
            <section dangerouslySetInnerHTML={{ __html: desc }} />
            <div>
              <img
                src={project.fields.mainImage.fields.file.url}
                style={{ width: "100%", borderRadius: 5, marginBottom: 10 }}
              />
            </div>
            {project.fields.mp3 &&
              project.fields.mp3.map((mp3) => (
                <div>
                  <small>{mp3.fields.title}</small>
                  <br />
                  <audio controls>
                    <source src={mp3.fields.file.url} />
                  </audio>
                </div>
              ))}
          </div>
          <div>
            {project.fields.mainVideo && (
              <div>
                <video controls>
                  <source
                    src={`${project.fields.mainVideo.fields.file.url}#t=1`}
                  ></source>
                </video>
              </div>
            )}
            <Link
              to={`/projects/${project.sys.id}`}
              type='button'
              className='btn btn-light'
            >
              read more
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;

import React from "react";
import { Link } from "react-router-dom";

const JSMCMenu = () => {
  return (
    <div style={{display:'flex', marginBottom:25, marginTop:-10}}>
      <Link to="/js-modular-cornett">
        <h6 className="link" style={{margin:'0 10px'}}>About the JSMC</h6>
      </Link>
      <Link to="/shop">
        <h6 className="link" style={{margin:'0 10px'}}>Online Shop</h6>
      </Link>
      <Link to="/jsmc-research-background">
        <h6 className="link" style={{margin:'0 10px'}}>Research Background</h6>
      </Link>
      <hr/>
    </div>
  );
};

export default JSMCMenu;

import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import marked from "marked";
import { Row, Col } from "react-bootstrap";
import { getProject } from "../contentful";
import ImageGrid from "./imageGrid/ImageGrid";
import Modal from "./imageGrid/Modal";
import { FaArrowLeft } from "react-icons/fa";
import { SRLWrapper } from "simple-react-lightbox";

const ProjectFull = () => {
  const { id } = useParams();
  const [project, setProject] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [title, setTitle] = useState("");

  useEffect(() => {
    getProject(id).then((data) => setProject(data));
  }, []);

  return (
    <div className='content'>
      {project.map((project) => {
        let desc;

        if (project.fields.description) {
          desc = marked(project.fields.description);
        }

        let mainText;
        if (project.fields.mainText) {
          mainText = marked(project.fields.mainText);
        }

        return (
          <>
            <h4 className='project-full-title'>{project.fields.title}</h4>
            <div className='project-full-main-content'>
              <section
                className='project-full-main-content-text'
                dangerouslySetInnerHTML={{ __html: desc }}
              />
              {project.fields.mp3 &&
                project.fields.mp3.map((mp3) => (
                  <div>
                    <small>{mp3.fields.title}</small>
                    <br />
                    <audio controls>
                      <source src={mp3.fields.file.url} />
                    </audio>
                  </div>
                ))}
              <div className='project-full-main-content-image'>
                <img src={project.fields.mainImage.fields.file.url} />
              </div>
            </div>
            {project.fields.mainVideo && (
              <div>
                <video controls>
                  <source
                    src={`${project.fields.mainVideo.fields.file.url}#t=1`}
                  ></source>
                </video>
              </div>
            )}

            <div
              style={{ marginTop: 20 }}
              dangerouslySetInnerHTML={{ __html: mainText }}
            />

            {project.fields.video &&
              project.fields.video.map((video) => (
                <video controls>
                  <source src={`${video.fields.file.url}#t=1`}></source>
                </video>
              ))}

            <SRLWrapper>
              {project.fields.imageGallery &&
                project.fields.imageGallery.map((image) => (
                  <img src={image.fields.file.url} alt={image.fields.title} />
                ))}
            </SRLWrapper>

            {/* { project.fields.imageGallery && <div> 
                    <ImageGrid images={project.fields.imageGallery} setSelectedImg={setSelectedImage} setTitle={setTitle}/>
                 {selectedImage && <Modal selectedImg={selectedImage} setSelectedImg={setSelectedImage} title={title} setTitle={setTitle} />}
                        </div>
                        } */}
          </>
        );
      })}
      <Link to='/projects' className='btn btn-sm btn-dark'>
        Projects Timeline <FaArrowLeft />
      </Link>
    </div>
  );
};

export default ProjectFull;

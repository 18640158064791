import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { ShopContext } from "../../context/ShopContext";
import {
  FaShoppingCart,
  FaArrowLeft,
  FaShoppingBasket,
  FaShoppingBag,
} from "react-icons/fa";
import TitleBar from "../TitleBar";
import marked from "marked";

const SingleProductPage = () => {
  const { product, fetchProductWithId, openCart, addItemToCart } = useContext(
    ShopContext
  );

  console.log("product", product);

  useEffect(() => {
    const id = window.location.pathname.split("shop/")[1];
    console.log(id);
    fetchProductWithId(id);
  }, [window.location.pathname]);

  if (!product) {
    return <h3>Loading...</h3>;
  }

  let desc;
  if (product.description) desc = marked(product.descriptionHtml);
  console.log(product);
  return (
    <div className='content' style={{ minHeight: "80vh" }}>
      <TitleBar title='The Shop' />
      <h4>{product.title}</h4>

      <Row>
        {product ? (
          <Col md={4}>
            {product?.images?.[0]?.src && (
              <img style={{ width: "100%" }} src={product?.images?.[0].src} />
            )}
          </Col>
        ) : (
          <div style={{ margin: 20 }}>
            <h5
              style={{
                border: "1px solid grey",
                textAlign: "center",
                padding: "20%",
              }}
            >
              Coming Soon
            </h5>
          </div>
        )}

        <Col md={8}>
          <div className='single-product-description'>
            <section dangerouslySetInnerHTML={{ __html: desc }} />
            <div>
              <button
                className='btn btn-light'
                onClick={() => {
                  addItemToCart(product.variants[0].id, 1);
                  openCart();
                }}
              >
                add to basket <FaShoppingBasket />
              </button>
              <button className='btn btn-dark' onClick={openCart}>
                show basket <FaShoppingBasket />
              </button>
            </div>
          </div>
        </Col>
      </Row>

      <div style={{ marginTop: 25 }}>
        <Link to='/shop' className='btn btn-dark'>
          <FaArrowLeft /> Back to all products
        </Link>
      </div>
    </div>
  );
};

export default SingleProductPage;

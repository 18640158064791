import React, {useState, useEffect} from 'react'
import { getJSCMVideos, getJSMCText } from '../contentful'
import TitleBar from './TitleBar'
import marked from 'marked'
import JSMCMenu from './JSMCMenu'

const JSModularCornett = () => {
    const [videos, setVideos] = useState([])
    const [text, setText] = useState([])

    useEffect(() => {
        getJSCMVideos().then(data => setVideos(data))
        getJSMCText().then(data => setText(data))
    },[])
    return (
        <div className="content">
            <TitleBar title="The JS Modular Cornett"/>
            <JSMCMenu/>

            {text.map(data => {
                    const text = marked(data.fields.content)
return <div dangerouslySetInnerHTML={{__html: text}} />
            })}
        {videos.map(video => 
            <video controls><source src={video.fields.video.fields.file.url} /></video>
            )}    
        </div>
    )
}

export default JSModularCornett
